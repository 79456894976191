<script setup>
import { vMaska } from 'maska';

import useForm from '@/composables/useForm';
import { ref, onMounted } from '#imports';
import { Field as VeeField } from 'vee-validate';

const formComposable = useForm();

const props = defineProps({
  type: {
    default: 'text',
    validator(value) {
      return ['text', 'email', 'search', 'password', 'number', 'date'].includes(
        value,
      );
    },
  },
  inputMode: {
    default: 'text',
    validator(value) {
      return ['text', 'decimal', 'none', 'email', 'numeric'].includes(value);
    },
  },
  label: {
    type: String,
    default: '',
  },
  hint: {
    type: String,
    default: '',
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  showErrors: {
    type: Boolean,
    default: true,
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: '',
  },
  mask: {
    required: true,
    type: String,
  },
  customInfo: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['update:modelValue', 'blur']);

const options = {
  onMaska: (detail) => {
    emit('update:modelValue', detail);
  },
};

const focused = ref(false);

function setFocus(value) {
  focused.value = Boolean(value);

  emit('blur');
}

//<editor-fold desc="Availability">
const randomId = ref();
const randomErrorId = ref();
const ariaDescribedby = ref();

onMounted(() => {
  randomId.value = formComposable.getRandomCode('input');
  randomErrorId.value = formComposable.getRandomCode('error');
  ariaDescribedby.value = randomErrorId.value;
});
//</editor-fold>
</script>

<template>
  <VeeField
    v-slot="{ field, errorMessage }"
    :model-value="modelValue"
    :name="name"
    :type="type"
    as="div"
  >
    <div
      class="ui-input"
      :class="{
        'ui-input--inner-indent': showErrors,
        'ui-input--disabled': isDisabled,
        'ui-input--has-value': modelValue,
        'ui-input--error': !!errorMessage,
        'ui-input--active': modelValue,
        'ui-input--focused': focused,
        'ui-input--custom-info': props.customInfo,
      }"
    >
      <div class="ui-input__header">
        <label :for="randomId" class="ui-input__label">
          <span class="ui-input__font ui-input__font--label">
            {{ label }}
          </span>
        </label>

        <div v-if="props.hint" class="ui-input__hint">
          <span class="ui-input__font ui-input__font--hint">
            {{ props.hint }}
          </span>
        </div>
      </div>

      <div class="ui-input__body">
        <span
          v-if="$slots.appendBefore"
          class="ui-input__append ui-input__append--before"
        >
          <slot name="appendBefore"></slot>
        </span>

        <input
          :id="randomId"
          ref="input"
          v-maska:[options]
          :data-maska="mask"
          v-bind="field"
          class="ui-input__field"
          :class="{
            [`ui-input__field--append-after`]: $slots.appendAfter,
            [`ui-input__field--append-before`]: $slots.appendBefore,
          }"
          :inputmode="inputMode"
          autocomplete="off"
          :disabled="isDisabled"
          :required="isRequired"
          :placeholder="placeholder"
          :aria-describedby="ariaDescribedby"
          :aria-required="isRequired"
          :aria-invalid="Boolean(props.showErrors && errorMessage)"
          @focus="setFocus(true)"
          @blur="setFocus(false)"
        />

        <span class="ui-input__append ui-input__append--after">
          <slot name="appendAfter"> </slot>
        </span>
      </div>

      <span
        v-show="showErrors && errorMessage"
        class="ui-input__footer"
        :title="errorMessage"
      >
        <span
          :id="randomErrorId"
          aria-live="assertive"
          class="ui-input__font ui-input__font--error"
          v-html="errorMessage"
        />
      </span>

      <span
        v-if="props.customInfo && !errorMessage && modelValue"
        class="ui-input__custom-info"
      >
        <span
          class="ui-input__font ui-input__font--custom-info"
          v-html="props.customInfo"
        />
      </span>
    </div>
  </VeeField>
</template>

<style scoped lang="scss">
.ui-input {
  $parent: &;
  $font-family: $font-family-default;
  $color-basis: $color-black-100;
  $color-label: $color-black-130;
  $color-label-active: $color-black-150;
  $color-hint: $color-black-120;
  $color-placeholder: $color-black-100;
  $color-border: $color-grey-200;
  $color-border-active: $color-grey-300;
  $color-error: $color-red-100;
  $color-accent: $color-orange-100;
  $color-bg: $color-white;
  $color-disabled: $color-grey-300;
  $color-bg-disabled: $color-white;
  $color-hover: $color-black-100;
  $inner-indent: 21;
  $font-size: 14;

  position: relative;
  font-family: $font-family;
  color: $color-basis;

  &__font {
    &--label {
      font-size: em(14);
      font-weight: 400;
      line-height: 1;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      transition: font-size 0.3s;

      @include media-breakpoint-down(lg) {
        font-size: em(10);
      }

      @include media-breakpoint-down(sm) {
        font-size: em(14);
      }
    }

    &--hint {
      font-size: em(12);
      line-height: 1;
      letter-spacing: 0.05em;
      transition: font-size 0.3s;

      @include media-breakpoint-down(lg) {
        font-size: em(10);
      }

      @include media-breakpoint-down(sm) {
        font-size: em(12);
      }
    }

    &--error {
      @include text-overflow;

      font-size: em(10);
      font-weight: 400;
      line-height: 1.2;
      color: $color-error;
      text-transform: uppercase;
      letter-spacing: 0.2em;

      @include media-breakpoint-down(lg) {
        font-size: em(8);
      }

      @include media-breakpoint-down(sm) {
        font-size: em(10);
      }
    }

    &--custom-info {
      @include text-overflow;

      font-size: em(10);
      font-weight: 500;
      line-height: 1;
      color: $color-accent;
      text-transform: uppercase;
    }
  }

  &__header {
  }

  &__body {
    position: relative;
    display: block;
    height: 100%;
    background: transparent;

    @include hover {
      #{$parent} {
        &__field {
          border-color: $color-border-active;
        }
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: em(2);
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
  }

  &__custom-info {
    position: absolute;
    bottom: em(2);
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
  }

  &__label {
    position: absolute;
    top: em(6);
    left: em(21);
    z-index: 1;
    display: block;
    color: $color-label;
    pointer-events: none;
    touch-action: none;
    cursor: auto;
    transition: all 0.3s;
    transform: translateY(em(14));

    @include media-breakpoint-down(lg) {
      top: 0;
      left: em(16);
    }

    @include media-breakpoint-down(sm) {
      top: em(6);
      left: em(21);
    }
  }

  &__hint {
    position: absolute;
    top: em(28);
    left: em(21);
    z-index: 1;
    display: block;
    color: $color-hint;
    pointer-events: none;
    touch-action: none;
    cursor: auto;
    opacity: 0;
    transition: opacity 0.3s;

    @include media-breakpoint-down(lg) {
      top: em(15);
      left: em(16);
    }

    @include media-breakpoint-down(sm) {
      top: em(24);
      left: em(21);
    }
  }

  &__field {
    width: 100%;
    height: 100%;
    padding: em(27, $font-size) em(20, $font-size) em(8, $font-size);
    font-size: em($font-size);
    font-family: $font-family;
    font-weight: 400;
    line-height: 1;
    color: $color-basis;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    background-color: $color-bg;
    border: none;
    border-bottom: 1px solid $color-border;
    border-radius: 0;
    transition: all 0.3s;

    @include media-breakpoint-down(lg) {
      font-size: em(10);
      padding: em(20, 10) em(15, 10) em(6, 10);
    }

    @include media-breakpoint-down(sm) {
      font-size: em($font-size);
      padding: em(27, $font-size) em(20, $font-size) em(8, $font-size);
    }

    &::placeholder {
      color: rgba($color-placeholder, 0.55);
    }

    &:focus,
    &:active {
      //color: rgba($color-grey-100, 0.55);
    }

    &--append-before {
      padding-left: em(40, $font-size);
    }

    &--append-after {
      padding-right: em(145, $font-size);
    }
  }

  &__append {
    position: absolute;
    top: 50%;
    display: flex;
    line-height: 1;
    cursor: pointer;
    transform: translateY(-50%);

    &--before {
      left: em(15);
    }

    &--after {
      right: em(15);
    }
  }

  &--error {
    #{$parent} {
      &__font--error {
        color: $color-error;
      }

      &__field {
        border-color: $color-error;
      }
    }

    &#{$parent}--custom-info#{$parent}--active {
      #{$parent} {
        &__field {
          border-color: $color-error;
        }
      }
    }
  }

  &--inner-indent {
    padding-bottom: em($inner-indent);

    @include media-breakpoint-down(lg) {
      padding-bottom: em(15);
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: em($inner-indent);
    }
  }

  &--focused {
    #{$parent} {
      &__font {
        &--label {
          font-size: em(10);
          color: $color-label-active;

          @include media-breakpoint-down(lg) {
            font-size: em(8);
          }

          @include media-breakpoint-down(sm) {
            font-size: em(10);
          }
        }
      }

      &__field {
        border-color: $color-border-active;
      }

      &__label {
        pointer-events: auto;
        touch-action: auto;
        transform: translateY(0);
      }

      &__hint {
        opacity: 1;
      }
    }

    &#{$parent}--custom-info#{$parent}--active {
      #{$parent} {
        &__field {
          border-color: $color-border;
        }

        &__custom-info {
          display: none;
        }
      }
    }
  }

  &--active {
    #{$parent} {
      &__font {
        &--label {
          font-size: em(10);
          color: $color-label-active;

          @include media-breakpoint-down(lg) {
            font-size: em(8);
          }

          @include media-breakpoint-down(sm) {
            font-size: em(10);
          }
        }
      }

      &__field {
        border-color: $color-border-active;
      }

      &__label {
        pointer-events: auto;
        touch-action: auto;
        transform: translateY(0);
      }

      &__hint {
        opacity: 1;
      }
    }
  }

  &--has-value {
    #{$parent} {
      &__hint {
        opacity: 0;
      }
    }
  }

  &--disabled {
    pointer-events: none;
    touch-action: none;
    cursor: default;

    @include hover {
      #{$parent} {
        &__field {
          color: $color-disabled;
          background: $color-bg-disabled;
          border-color: $color-border;
          box-shadow: none;
        }
      }
    }

    #{$parent} {
      &__field {
        color: $color-disabled;
        background: $color-bg-disabled;
        border-color: $color-border;
      }

      &__body {
        position: relative;
        display: block;
        height: 100%;
        background: transparent;
      }
    }
  }

  &--custom-info {
    &#{$parent}--active {
      #{$parent} {
        &__field {
          border-color: $color-accent;
        }
      }
    }
  }
}
</style>
